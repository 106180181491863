import { turboRequest } from '../../../helpers/turbo_helper'
import { Controller } from '@hotwired/stimulus'
import { debounce } from 'underscore'

export default class extends Controller {
  connect() {
    this.filter = debounce(this.filter.bind(this), 300)
  }

  filter() {
    this.element.requestSubmit()
  }
}
